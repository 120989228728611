// エディタ

<template lang="pug">
tiny-mce(
  :init='initialization',
  :value='value',
  @input='$emit("input", $event)'
)
</template>

<script>
import TinyMce from '@tinymce/tinymce-vue'

export default {
  name: 'Editor',

  components: { TinyMce },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      initialization: {
        plugins: ['link', 'lists', 'autoresize'],
        // プラグインを追加する場合は、tinymce.jsも編集
        menubar: false,
        language: 'ja',
        // `/public` 以下に配置
        language_url: '/tinymce/langs/ja.js',
        toolbar: [
          'undo redo',
          'formatselect',
          'forecolor',
          'bold italic underline strikethrough',
          'alignleft aligncenter alignright',
          'bullist numlist',
          'link unlink',
        ].join('|'),
        branding: false,
        min_height: 400,
      },
    }
  },
}
</script>

<style lang="sass" scoped></style>
