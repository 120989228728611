<!-- 自園の情報編集ダイアログ -->

<template lang="pug">
el-dialog.modify-own-organization-dialog(
  title='自園の基本情報の編集',
  width='70%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  //- フォーム
  el-form(
    v-if='profile'
    :model='form',
    :rules='rules',
    label-position='top',
    ref='form'
  )
    el-form-item(label='園名 (変更不可)')
      p {{ profile.corporateName }} {{ profile.name }}
      p.text-sm ({{ profile.corporateNameKana }} {{ profile.nameKana }})

    el-form-item(
      label='紹介文 (100字まで)',
      prop='summary'
    )
      el-input(v-model='form.summary')
      p.mt-1.text-xs.text-green-700 ※協会のホームページやアプリに掲載される簡単な説明です。

    el-row.row(:gutter='20')
      el-col(:span='12')
        el-form-item(
          label='TEL (ハイフン無し)'
          prop='tel'
        )
          el-input(v-model='form.tel')
      el-col(:span='12')
        el-form-item(
          label='FAX (ハイフン無し)'
          prop='fax'
        )
          el-input(v-model='form.fax')

    el-form-item(
      label='郵便番号 (ハイフン無し)',
      prop='zip',
    )
      el-input.w-40(v-model='form.zip')

    el-form-item(
      label='住所',
      prop='address'
    )
      el-input(v-model='form.address')

    el-form-item(
      label='メールアドレス (複数入力時は改行またはコンマで区切る)',
      prop='emails'
    )
      el-input(
        type='textarea',
        v-model='emailsStr',
        autosize
      )

    el-form-item(
      label='問い合わせの可否'
    )
      el-checkbox(
        v-model='form.canContactFromWeb'
      ) 協会ホームページからの問い合わせを許可
      el-checkbox(
        v-model='form.canContact'
      ) スマホアプリからの問い合わせを許可

    el-form-item(
      label='問い合わせ用メールアドレス (複数入力時は改行かコンマで区切る)',
      prop='emailsForContact',
      v-show='form.canContact || form.canContactFromWeb'
    )
      el-input(
        type='textarea',
        v-model='emailsForContactStr',
        autosize
      )
      p.mt-1.text-xs.text-green-700
        | ※協会のホームページやアプリからの問い合わせの際、お知らせを送るメールアドレスを指定します。
        | 空の場合は、通常のメールアドレスが使用されます。

    el-form-item(
      label='ホームページ',
      prop='url'
    )
      el-input(v-model='form.url')

    el-form-item(
      v-for='group in allTags'
      :label='group.tagTypeDescription',
      :key='group.tagTypeCode'
    )
      p.text-xs.text-red-500 ※求職者向けページに表示
      el-checkbox-group(
        v-model='form.tags'
      )
        el-checkbox(
          v-for='item in group.items'
          :key='item.id',
          :label='item.id'
        ) {{ item.description }}

    el-form-item(
      label='行っていること'
    )
      p.text-xs.text-red-500 ※保護者向けページに表示
      ul
        li.mb-3.flex(v-for='item, i in form.measuresForParent')
          div.flex-1
            el-input(
              type='textarea',
              v-model='form.measuresForParent[i]',
              :rows='3'
            )
            p.mt-1.text-xs.text-green-700(v-if='i === 0')
              | 保護者向けに実施している事業についての説明です。
          //- 削除ボタン。最初の項目は削除不可
          div.ml-2(v-if='i > 0')
            el-button(
              type='danger',
              size='small',
              @click='form.measuresForParent.splice(i, 1)'
            ) 削除
      p.text-center
        el-button(
          type='primary',
          size='small',
          icon='el-icon-plus',
          @click='form.measuresForParent.push("")'
        ) 段落を追加

    el-form-item(
      label='その他'
    )
      p.text-xs.text-red-500 ※保護者向けページに表示
      el-input(
        type='textarea',
        v-model='form.noticeForParent',
        :rows='3'
      )
      p.mt-1.text-xs.text-green-700
        | その他、保護者向けに訴求したい事項があれば記入してください。
  //- ボタン群
  .mt-3
    el-button(
      type='primary',
      icon='el-icon-edit',
      @click='save'
    ) 登録
    el-button(
      @click='close(false)'
    ) キャンセル
</template>

<script>
import formOrganizationTags from '@/helpers/form-organization-tags'
import organizationService from '@/services/organization'

export default {
  name: 'ModifyOwnOrganizationDialog',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      form: {
        // キャッチコピー
        summary: undefined,
        tel: undefined,
        fax: undefined,
        zip: undefined,
        address: undefined,
        canContact: false,
        canContactFromWeb: false,
        emails: undefined,
        emailsForContact: undefined,
        url: undefined,
        tags: undefined,
        measuresForParent: undefined,
        noticeForParent: undefined,
      },
      // バリデーションルール
      rules: {
        summary: [{ type: 'string', max: 100, message: '最大100文字までです。' }],
        tel: [{ pattern: /^\d{10,11}$/, message: '入力が正しくありません。' }],
        fax: [{ pattern: /^\d{10,11}$/, message: '入力が正しくありません。' }],
        zip: [{ pattern: /^\d{7}$/, message: '入力が正しくありません。' }],
        emails: [
          {
            type: 'array',
            defaultField: { type: 'email', message: 'メールアドレスを入力してください。' },
          },
        ],
        emailsForContact: [
          {
            type: 'array',
            defaultField: { type: 'email', message: 'メールアドレスを入力してください。' },
          },
        ],
        url: [{ type: 'url', message: '正しい形式で入力してください。' }],
      },
      emailsStr: undefined,
      emailsForContactStr: undefined,
      // 自園の情報
      profile: undefined,
      // タグ情報
      allTags: [],
    }
  },
  computed: {},
  async created() {
    try {
      const res = await organizationService.getOrganizationTagList()
      if (!res.ok) throw new Error('cannot get organization tag list')
      this.allTags = formOrganizationTags(res.payload.items)
    } catch (error) {
      console.error(error)
    }
  },
  mounted() {
    // 開発時のみ。後から削除
    this.open()
  },
  methods: {
    async open() {
      try {
        // フォームをリセット
        if (this.$refs.form) this.$refs.form.resetFields()

        // 自園の情報を改めて取得
        const res = await organizationService.getMeForOrg()
        if (!res.ok) throw new Error('cannot get organization.')
        this.profile = res.payload

        // フォームの値を初期化
        this._initFormValues(res.payload)
      } catch (error) {
        console.error(error)
      }
    },

    _initFormValues(values) {
      this.form.summary = values.summary
      this.form.tel = values.tel
      this.form.fax = values.fax
      this.form.zip = values.zip
      this.form.address = values.address
      this.form.canContact = values.canContact
      this.form.canContactFromWeb = values.canContactFromWeb
      if (values.emails && values.emails.length > 0) {
        this.emailsStr = values.emails.join('\n')
      } else {
        this.emailsStr = undefined
      }
      if (values.emailsForContact && values.emailsForContact.length > 0) {
        this.emailsForContactStr = values.emailsForContact.join('\n')
      } else {
        this.emailsForContactStr = undefined
      }
      this.form.url = values.url
      this.form.tags = (values.tags || []).map((item) => item.id)
      if (values.measuresForParent && values.measuresForParent.length > 0) {
        this.form.measuresForParent = values.measuresForParent
      } else {
        this.form.measuresForParent = ['']
      }
      if (values.noticeForParent) {
        this.form.noticeForParent = values.noticeForParent
      } else {
        this.form.noticeForParent = ''
      }
    },

    async save() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      const res = await organizationService.updateMeForOrg(this.form)
      if (!res.ok) {
        this.$alert('保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      // ダイアログを閉じる
      this.close(true)
    },

    close(success) {
      if (success) this.$emit('ok')
      this.$emit('update:visible', false)
    },
  },

  watch: {
    emailsStr(value) {
      if (!value) {
        this.form.emails = []
        return
      }
      const _value = value.trim()
      this.form.emails = _value.split(/[\n,]/).filter((item) => item)
    },
    emailsForContactStr(value) {
      if (!value) {
        this.form.emailsForContact = []
        return
      }
      const _value = value.trim()
      this.form.emailsForContact = _value.split(/[\n,]/).filter((item) => item)
    },
  },
}
</script>

<style lang="sass">
.modify-own-organization-dialog
  // ダイアログのコンテントの上部に大きな余白があるので無くす
  .el-dialog__body
    padding-top: 1rem
  // ラベルの調整
  .el-form-item__label
    font-weight: bold
    color: theme('colors.gray.500')
    padding-bottom: 0
  // スマホのときだけダイアログを画面全体に
  +mobile
    .el-dialog
      margin-top: 2rem !important
      width: 90% !important
</style>

<style lang="sass" scoped></style>
