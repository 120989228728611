// 自園の情報

<template lang="pug">
el-card.member-profile
  //- 戻るリンク
  div
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  el-tabs(type='card', v-model='activeTabName')

    el-tab-pane(name='profile', label='基本情報')
      table.mt-2.border-collapse.w-full(v-if='profile')
        tbody
          tr
            th 園名
            td
              p {{ profile.corporateName }} {{ profile.name }}
              p.text-sm {{ profile.corporateNameKana }} {{ profile.nameKana }}
          tr
            th 紹介文
            td
              span(v-if='profile.summary') {{ profile.summary }}
              span.text-gray-400(v-else) (なし)
          tr
            th TEL
            td
              span(v-if='profile.tel') {{ profile.tel }}
              span.text-gray-400(v-else) (なし)
          tr
            th FAX
            td
              span(v-if='profile.fax') {{ profile.fax }}
              span.text-gray-400(v-else) (なし)
          tr
            th 住所
            td
              p 〒{{ profile.zip }}
              p {{ profile.address }}
          tr
            th メールアドレス
            td
              ul(v-if='profile.emails')
                li.ml-6.list-disc(v-for='item in profile.emails') {{ item }}
              span.text-gray-400(v-else) (なし)
          tr
            th 問い合わせ可否
            td

              p.text-sm.text-teal-700 ホームページからの問い合わせ:
              p {{ profile.canContactFromWeb ? '許可する' : '許可しない' }}

              p.text-sm.text-teal-700 スマホアプリからの問い合わせ:
              p {{ profile.canContact ? '許可する' : '許可しない' }}

              template(v-if='(profile.canContact || profile.canContactFromWeb) && profile.emailsForContact')
                p.mt-4.text-sm.text-teal-700 問い合わせ時の通知先:
                ul
                  li.ml-6.list-disc(v-for='item in profile.emailsForContact') {{ item }}
          tr
            th ホームページ
            td
              a(
                v-if='profile.url',
                :href='profile.url'
                target='_blank',
                rel='noopener'
              ) {{ profile.url }}
              span.text-gray-400(v-else) (なし)

          //- タグは現状、求人情報しかない
          tr
            th
              | 求人情報
              p.text-sm.font-normal.text-red-500 ※求職者向けページに表示
            td
              ul(v-if='profile.tags && profile.tags.length > 0')
                li.ml-6.list-disc(v-for='item in profile.tags[0].items') {{ item.description }}
              p.text-gray-400(v-else) (なし)

          tr
            th
              | 行っていること
              p.text-sm.font-normal.text-red-500 ※保護者向けページに表示
            td
              ul(v-if='profile.measuresForParent && profile.measuresForParent.length > 0')
                li.mb-3.whitespace-pre-line(v-for='item in profile.measuresForParent') {{ item }}
              p.text-gray-400(v-else) (なし)

          tr
            th
              | その他
              p.text-sm.font-normal.text-red-500 ※保護者向けページに表示
            td
              p.whitespace-pre-line(v-if='profile.noticeForParent') {{ profile.noticeForParent }}
              p.text-gray-400(v-else) (なし)

      div.mt-4
        el-button(
          type='primary',
          icon='el-icon-edit',
          @click='openModifyOwnOrganizationDialog'
        ) 基本情報の修正

    el-tab-pane(name='photos', label='園の写真')

      p
        b 写真を追加
        | を押して園舎・園庭等の画像を追加して下さい。
      p.text-sm.text-green-700 ※ JPG形式のみに対応しています。また、長辺1000px程度の解像度推奨です。

      ul.mt-2.overflow-hidden
        li.m-2.float-left.w-48.h-48.bg-top.bg-cover.rounded.relative(
          v-for='item in photos',
          :style='{ "background-image": `url("${item.url}")` }'
        )
          //- 削除ボタン
          .w-6.h-6.rounded-full.flex.absolute.items-center.justify-center.bg-red-500.text-white.cursor-pointer(
            style='right: -0.3rem; top: -0.3rem',
            title='削除',
            @click='removePhoto(item)'
          )
            fa-icon(icon='times')

        li.flex.m-2.float-left.w-48.h-48.rounded.bg-gray-200.hover__bg-yellow-100
          file-upload.w-full.h-full.items-center.justify-center(
            ref='upload',
            :custom-action='upload',
            accept='image/jpeg',
            @input-file='inputFile',
            style='display: flex;'
          )
            p.text-gray-600
              span.text-xl
                fa-icon(icon='plus')
              br
              span 写真を追加

    el-tab-pane(name='content', label='お知らせ(求職者向け)')
      p
        | スマホアプリに表示されるお知らせを編集します。編集が完了しましたら、
        b 変更を保存
        | ボタンを押して下さい。
      
      .mt-2
        el-button(
          type='primary',
          icon='el-icon-check',
          @click='savePage',
          :disabled='!pageBodyIsDirty'
        ) 変更を保存

      .mt-3
        editor(v-model='pageBody')

    el-tab-pane(name='information-for-parent', label='お知らせ(保護者向け)')

      el-button(
        type='primary',
        icon='el-icon-circle-plus',
        @click='openModifyOrganizationInformationDialog'
      ) お知らせを作成

      el-table.information-list(:data='informations')

        el-table-column(
          label='状態'
        )
          template(slot-scope='scope')
            .inline-block.p-1.rounded-full.text-xs.font-bold.text-white(
              :class='[scope.row.isOpened ? "bg-teal-500" : "bg-gray-400"]'
            ) {{ scope.row.isOpened ? '公開中' : '非公開' }}

        el-table-column(
          label='表示日時'
        )
          template(slot-scope='scope')
            span {{ scope.row.date | jpDate }}

        el-table-column(
          label='タイトル',
          prop='title'
        )
          template(slot-scope='scope')
            span {{ scope.row.title }}
            fa-icon.ml-1.text-blue-500(
              v-if='scope.row.hasAttachments',
              icon='paperclip'
            ) 

        el-table-column(
          label='アプリ通知',
          prop='notifiedAt',
        )
          template(slot-scope='scope')
            p(v-if='scope.row.notifiedAt') {{ scope.row.notifiedAt | jpDateTime }}
            p(v-else) (未通知)

        el-table-column(
          align='right'
        )
          template(slot-scope='scope')
            el-button(
              size='mini',
              type='success',
              plain,
              @click='modifyInformationForParent(scope.row.id)'
            ) 編集
            el-button(
              size='mini',
              type='danger',
              plain,
              @click='removeInformationForParent(scope.row.id)'
            ) 削除
  modify-own-organization-dialog(
    :visible.sync='modifyOwnOrganizationDialogVisible',
    @ok='updateBasicInfoSuccess'
  )
  modify-organization-information-dialog(
    :visible.sync='modifyOrganizationInformationDialogVisible',
    :organization-information-id='currentInformationId',
    @ok='updateInformationSuccess'
  )
</template>

<script>
import FileUpload from 'vue-upload-component'

import uploadFile from '@/api/admin/upload-file'
import ModifyOwnOrganizationDialog from '@/dialogs/modify-own-organization-dialog.vue'
import ModifyOrganizationInformationDialog from '@/dialogs/modify-organization-information-dialog.vue'
import organizationService from '@/services/organization'
import formOrganizationTags from '@/helpers/form-organization-tags'
import Editor from '@/components/editor.vue'

export default {
  name: 'MemberProfile',

  props: {},
  components: {
    FileUpload,
    ModifyOwnOrganizationDialog,
    ModifyOrganizationInformationDialog,
    Editor,
  },

  data() {
    return {
      activeTabName: 'profile',
      profile: null,
      modifyOwnOrganizationDialogVisible: false,
      modifyOrganizationInformationDialogVisible: false,
      // アップロード前のファイル
      files: [],
      // 写真要素
      photos: [],
      // お知らせページの内容
      pageBody: '',
      // お知らせページの保存されている内容
      pageBodyPrev: '',
      // 保護者向けのお知らせ一覧
      informations: [],
      // 編集対象の保護者向けのお知らせID
      currentInformationId: undefined,
    }
  },

  computed: {
    pageBodyIsDirty() {
      return this.pageBody !== this.pageBodyPrev
    },
  },

  created() {
    this._initProfile()
  },

  methods: {
    // 基本情報の取得
    async _initProfile() {
      try {
        const res = await organizationService.getMeForOrg()
        if (!res.ok) throw new Error('cannot get organization.')
        this.profile = {
          ...res.payload,
          // タグ情報のみ整形
          tags: formOrganizationTags(res.payload.tags),
        }
        this.photos = res.payload.photos
        this.pageBody = this.pageBodyPrev = res.payload.pageBody || ''
        // 保護者向けのお知らせ一覧も取得
        const res2 = await organizationService.getInformationsForParent()
        if (!res2.ok) throw new Error('cannot get informations for parent.')
        this.informations = res2.payload.items
      } catch (error) {
        console.error(error)
      }
    },

    openModifyOwnOrganizationDialog() {
      this.modifyOwnOrganizationDialogVisible = true
    },
    openModifyOrganizationInformationDialog() {
      this.currentInformationId = undefined
      this.modifyOrganizationInformationDialogVisible = true
    },
    modifyInformationForParent(id) {
      this.currentInformationId = id
      this.modifyOrganizationInformationDialogVisible = true
    },

    async removeInformationForParent(id) {
      try {
        await this.$confirm('本当に削除しますか？', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
          type: 'warning',
        })
      } catch {
        // キャンセルが押された
        return
      }
      try {
        const res = await organizationService.removeInformationForParent(id)
        if (!res.ok) throw new Error('cannot remove information for parent.')
        this.$message({
          message: '削除しました。',
          type: 'success',
        })
        // フォーカスを外す
        if (document.activeElement) document.activeElement.blur()
        await this._initProfile()
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    // 更新が完了
    updateBasicInfoSuccess() {
      this.$message({
        message: '基本情報を更新しました。',
        type: 'success',
      })
      this._initProfile()
    },

    updateInformationSuccess() {
      this.$message({
        message: '保護者向けのお知らせを更新しました。',
        type: 'success',
      })
      this._initProfile()
    },

    // ファイルのアップロード
    async upload(file) {
      const res = await organizationService.getFileUploadUrlForOrg('photos', file.name)
      if (!res.ok) return
      const { getUrl, putUrl, id } = res.payload
      const res2 = await uploadFile(putUrl, file.file)
      if (!res2.ok) return
      // 新しい写真リスト
      const newPhotos = [
        ...this.photos,
        {
          id,
          filename: file.name,
          url: getUrl,
        },
      ]
      // 写真の情報を記録
      await organizationService.updatePhotosForOrg(newPhotos.map((item) => item.id))
      // 画面を更新
      this.photos = newPhotos
    },

    inputFile() {
      if (this.$refs.upload) {
        if (!this.$refs.upload.active) this.$refs.upload.active = true
      }
    },

    // xボタンがクリックされた画像を削除
    async removePhoto(target) {
      const filtered = this.photos.filter((item) => item.id !== target.id)
      await organizationService.updatePhotosForOrg([...filtered.map((item) => item.id)])
      this.photos = filtered
    },

    // お知らせページの内容を保存
    async savePage() {
      await organizationService.updatePageForOrg(this.pageBody)
      // dirtyの初期化
      this.pageBodyPrev = this.pageBody
      this.$message({
        message: 'お知らせの内容を更新しました。',
        type: 'success',
      })
    },
  },
}
</script>

<style lang="sass">
.member-profile
  .el-tabs__item
    font-size: 1rem
    &.is-active
      font-weight: bold
      text-decoration: underline
</style>

<style lang="sass" scoped>
table
  th, td
    border: 1px solid theme('colors.gray.300')
    padding: 0.3rem
  th
    color: theme('colors.gray.500')
    background: theme('colors.yellow.50')
</style>
