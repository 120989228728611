<!-- 園から保護者へのお知らせの作成・変更のためのダイアログ -->

<template lang="pug">
el-dialog.organization-information-dialog(
  title='お知らせの作成と編集',
  width='80%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article

    el-form(:model='form', :rules='rules', ref='form')

      el-form-item(label='表示する日付', prop='displayDate')
        el-date-picker(
          type='date',
          v-model='form.displayDate'
        )


      el-form-item(label='公開', prop='isOpened')
        el-switch(
          active-text='する',
          inactive-text='しない',
          v-model='form.isOpened'
        )

      el-form-item(label='タイトル', prop='title')
        el-input(
          v-model='form.title'
        )

      el-form-item(label='本文', prop='body')
        el-input(
          type='textarea',
          :rows='8',
          v-model='form.body'
        )

      //- すでに通知済みなら表示しない
      el-form-item(
        v-if='form.isOpened'
        label='アプリへの通知',
        prop='notify'
      )
        el-checkbox(
          v-if='!notifiedAt'
          v-model='form.notify'
        )
          | 通知する
        p(v-else)
          | 通知済み ({{ notifiedAt | jpDateTime }})
        p.text-sm.text-green-700 ※アプリを利用している保護者で自園を登録している人に通知します。

    footer
      el-button(
        v-if='this.form.isOpened'
        type='primary',
        @click='save'
      ) 投稿
      el-button(
        v-else,
        type='primary',
        @click='save'
      ) 下書き保存
      el-button(@click='close(false)') キャンセル
</template>

<script>
import organizationService from '@/services/organization'

export default {
  name: 'ModifyOrganizationInformationDialog',

  data() {
    return {
      form: {
        displayDate: undefined,
        isOpened: false,
        title: '',
        body: '',
        notify: false,
      },
      rules: {
        displayDate: [{ required: true, message: '日付を選択してください。' }],
        title: [{ required: true, message: 'タイトルを入力してください。' }],
        body: [{ required: true, message: '本文を入力してください。' }],
      },
      // すでにアプリ通知済みならその時刻
      notifiedAt: undefined,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    organizationInformationId: {
      type: Number,
    },
  },

  methods: {
    open() {
      if (this.$refs.form) this.$refs.form.resetFields()
      if (this.organizationInformationId) this._initForModify()
      else this._initForCreate()
    },

    close(success) {
      if (success) this.$emit('ok')
      this.$emit('update:visible', false)
    },

    // 編集のための準備
    async _initForModify() {
      const response = await organizationService.getInformationForParent(
        this.organizationInformationId
      )
      if (!response.ok) return
      const detail = response.payload
      this.form.displayDate = detail.displayDate
        ? new Date(detail.displayDate.replace(/-/g, '/'))
        : null
      this.form.isOpened = detail.isOpened
      this.form.title = detail.title
      this.form.body = detail.body
      this.form.notify = false
      this.notifiedAt = detail.notifiedAt
    },

    _initForCreate() {
      this.form.displayDate = null
      this.form.isOpened = false
      this.form.title = ''
      this.form.body = ''
      this.form.notify = false
      this.notifiedAt = undefined
    },

    async save() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      let response
      if (this.organizationInformationId) {
        response = await organizationService.updateInformationForParent({
          informationId: this.organizationInformationId,
          ...this.form,
        })
      } else {
        response = await organizationService.createInformationForParent(this.form)
      }
      if (!response.ok) {
        this.$alert('お知らせの保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      this.close(true)
    },
  },
}
</script>
