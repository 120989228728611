// 園のタグ情報を整形

import _groupBy from 'lodash.groupby'
import _first from 'lodash.first'
import _pickBy from 'lodash.pickby'
import _omitBy from 'lodash.omitby'

export default (tags) => {
  if (!tags) return []
  const grouped = _groupBy(tags, (item) => item.tagTypeCode)
  return Object.values(grouped)
    .map((items) => {
      const obj = _first(items)
      return {
        ..._pickBy(obj, (_, key) => /^tagType/.test(key)),
        items: items.map((item) => _omitBy(item, (_, key) => /^tagType/.test(key))),
      }
    })
    .sort((a, b) => a.tagTypeOrder - b.tagTypeOrder)
}
