// 園関連

import api from '@/helpers/api'

// 団体の一覧を取得
async function getOrganizationList() {
  const path = '/organizations'
  return api({
    url: path,
  })
}

// タグの一覧を取得
async function getOrganizationTagList() {
  const path = '/organization-tags'
  return api({
    url: path,
    needsAuth: true,
  })
}

// 団体の作成 (管理者用)
async function createOrganization({
  membershipNumber,
  regionId,
  corporateName,
  corporateNameKana,
  name,
  nameKana,
  tel,
  fax,
  zip,
  address,
  emails,
  emailsForContact,
  lat,
  lng,
  url,
  canContact,
  canContactFromWeb,
  tags,
}) {
  const path = '/admin/organizations'
  return api({
    method: 'post',
    url: path,
    body: {
      membershipNumber,
      regionId,
      corporateName,
      corporateNameKana,
      name,
      nameKana,
      tel,
      fax,
      zip,
      address,
      emails: emails && emails.length ? emails : null,
      emailsForContact: emailsForContact && emailsForContact.length ? emailsForContact : null,
      lat: lat || null,
      lng: lng || null,
      url,
      canContact,
      canContactFromWeb,
      tags,
    },
    needsAuth: true,
  })
}

// 管理者向けの団体の取得 (管理者用)
async function getOrganizationListForAdmin() {
  const path = '/admin/organizations'
  return api({
    url: path,
    needsAuth: true,
  })
}

// 特定の団体のメンバーのリストの取得 (管理者用)
async function getOrganizationMemberList(organizationId) {
  const path = '/admin/organizations/{id}/members'
  const url = path.replace('{id}', organizationId)
  return api({
    url,
    needsAuth: true,
  })
}

// 団体の詳細の取得 (管理者用)
async function getOrganization(organizationId) {
  const path = '/admin/organizations/{id}'
  const url = path.replace('{id}', organizationId)
  return api({
    url,
    needsAuth: true,
  })
}

// 団体の削除 (管理者用)
async function removeOrganization(organizationId) {
  const path = '/admin/organizations/{id}'
  const url = path.replace('{id}', organizationId)
  return api({
    url,
    method: 'delete',
    needsAuth: true,
  })
}

// 団体の情報の更新 (管理者向け) (管理者用)
async function updateOrganization(
  organizationId,
  {
    membershipNumber,
    regionId,
    corporateName,
    corporateNameKana,
    name,
    nameKana,
    tel,
    fax,
    zip,
    address,
    emails,
    emailsForContact,
    lat,
    lng,
    url,
    canContact,
    canContactFromWeb,
    tags,
  }
) {
  const path = '/admin/organizations/{id}'
  return api({
    method: 'put',
    url: path.replace('{id}', organizationId),
    body: {
      membershipNumber,
      regionId,
      corporateName,
      corporateNameKana,
      name,
      nameKana,
      tel,
      fax,
      zip,
      address,
      emails: emails && emails.length ? emails : null,
      emailsForContact: emailsForContact && emailsForContact.length ? emailsForContact : null,
      lat: lat || null,
      lng: lng || null,
      url,
      canContact,
      canContactFromWeb,
      tags,
    },
    needsAuth: true,
  })
}

// 自分の園情報を取得 (園用)
async function getMeForOrg() {
  const path = '/organizations/profile'
  return api({
    url: path,
    needsAuth: true,
  })
}

// 自分の園の情報を更新 (園用)
async function updateMeForOrg({
  summary,
  tel,
  fax,
  zip,
  address,
  canContact,
  canContactFromWeb,
  emails,
  emailsForContact,
  url,
  tags,
  measuresForParent,
  noticeForParent,
}) {
  const path = '/organizations/profile'
  // 空のアイテムは除去
  const _measuresForParent = measuresForParent.filter((item) => item)
  return api({
    method: 'put',
    url: path,
    body: {
      // 空文字 '' ならundefinedとする
      summary: summary || undefined,
      tel: tel || undefined,
      fax: fax || undefined,
      zip: zip || undefined,
      address: address || undefined,
      canContact,
      canContactFromWeb,
      emails: emails && emails.length ? emails : undefined,
      emailsForContact: emailsForContact && emailsForContact.length ? emailsForContact : undefined,
      url: url || undefined,
      tags: tags && tags.length ? tags : undefined,
      measuresForParent: _measuresForParent.length > 0 ? _measuresForParent : undefined,
      noticeForParent: noticeForParent || undefined,
    },
    needsAuth: true,
  })
}

// 自園に関するファイルのアップロードURLの取得 (園用)
async function getFileUploadUrlForOrg(type, filename) {
  const path = '/organizations/upload-url'
  return api({
    url: path,
    params: {
      type,
      filename,
    },
    needsAuth: true,
  })
}

// 自園の写真の更新 (園用)
async function updatePhotosForOrg(photoFileIds) {
  const path = '/organizations/photos'
  return api({
    method: 'put',
    url: path,
    body: {
      items: photoFileIds,
    },
    needsAuth: true,
  })
}

// 自園のお知らせの更新 (園用)
async function updatePageForOrg(body) {
  const path = '/organizations/page'
  return api({
    method: 'put',
    url: path,
    body: {
      body,
    },
    needsAuth: true,
  })
}

// 自園の保護者向けのお知らせの一覧取得 (園用)
async function getInformationsForParent() {
  const path = '/organizations/informations'
  return api({
    url: path,
    needsAuth: true,
  })
}

// 自園の保護者向けの特定のお知らせの取得 (園用)
async function getInformationForParent(informationId) {
  const path = '/organizations/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    url,
    needsAuth: true,
  })
}

// 自園の保護者向けのお知らせの更新 (園用)
async function updateInformationForParent({
  informationId,
  isOpened = false,
  displayDate,
  title,
  body,
  notify = false,
}) {
  const path = '/organizations/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    method: 'put',
    url,
    body: {
      isOpened,
      displayDate,
      title,
      body,
      notify,
    },
    needsAuth: true,
  })
}

// 自園の保護者向けの情報の削除 (園用)
async function removeInformationForParent(informationId) {
  const path = '/organizations/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    url,
    method: 'delete',
    needsAuth: true,
  })
}

// 自園の保護者向けのお知らせの追加 (園用)
async function createInformationForParent({
  isOpened = false,
  displayDate,
  title,
  body,
  notify = false,
}) {
  const path = '/organizations/informations'
  return api({
    method: 'post',
    url: path,
    body: {
      isOpened,
      displayDate,
      title,
      body,
      notify,
    },
    needsAuth: true,
  })
}

export default {
  getOrganizationList,
  getOrganizationTagList,
  createOrganization,
  getOrganizationListForAdmin,
  getOrganizationMemberList,
  getOrganization,
  removeOrganization,
  updateOrganization,
  getMeForOrg,
  updateMeForOrg,
  getFileUploadUrlForOrg,
  updatePhotosForOrg,
  updatePageForOrg,
  updateInformationForParent,
  getInformationsForParent,
  getInformationForParent,
  removeInformationForParent,
  createInformationForParent,
}
